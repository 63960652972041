<template>
  <div class="Nav">
    <div
      class="contenedor d-flex"
      style="
        height: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0 100px;
      "
    >

          
            <div class="listadoJuegosLeyend d-flex flex-row">
              <small class="mr-3" style="font-weight:700">Estado de los juegos</small>
              <div
                class="d-flex flex-row mr-4"
                style="align-items: center"
              >
                <i
                  class="fas fa-circle"
                  style="margin-right: 10px; font-size: 10px; color: gray"
                ></i>
                Borrador
              </div>
              <div
                class="d-flex flex-row mr-4"
                style="align-items: center"
              >
                <i
                  class="fas fa-circle"
                  style="margin-right: 10px; font-size: 10px; color: rgb(246, 201, 1)"
                ></i>
                Completo
              </div>
              <div
                class="d-flex flex-row mr-4"
                style="align-items: center"
              >
                <i
                  class="fas fa-circle"
                  style="margin-right: 10px; font-size: 10px; color: #f44336"
                ></i>
                Corregir
              </div>
              <div
                class="d-flex flex-row mr-4"
                style="align-items: center"
              >
                <i
                  class="fas fa-circle"
                  style="margin-right: 10px; font-size: 10px; color: #4caf50"
                ></i>
                Aprobado
              </div>
              <div
                class="d-flex flex-row mr-4"
                style="align-items: center"
              >
                <i
                  class="fas fa-circle"
                  style="margin-right: 10px; font-size: 10px; color: rgb(15, 139, 215)"
                ></i>
                Utilizado
              </div>
            </div>



      <div class="d-flex flex-row" style="align-items: center">
        <img src="/marca/logo.png" alt="" style="width: 105px" />
        <div
          v-if="$route.name == 'updateJuego'"
          style="
            margin-left: 40px;
            font-weight: 600;
            text-transform: uppercase;
            color: #333;
          "
        >
          Actualizar juego
        </div>
        <div
          v-else-if="$route.name == 'createJuego'"
          style="
            margin-left: 40px;
            font-weight: 600;
            text-transform: uppercase;
            color: #333;
          "
        >
          Crear nuevo juego
        </div>
        <div
          v-else-if="$route.name == 'createPrograma'"
          style="
            margin-left: 40px;
            font-weight: 600;
            text-transform: uppercase;
            color: #333;
          "
        >
          Crear nuevo programa
        </div>
        <div
          v-else-if="$route.name == 'updatePrograma'"
          style="
            margin-left: 40px;
            font-weight: 600;
            text-transform: uppercase;
            color: #333;
          "
        >
         Actualizar programa
        </div>


      </div>

      <div
        class="notificacion d-flex"
        style="height: 100%; align-items: center; justify-content: right; font-size: 22px"
      >
        <i class="far fa-times" @click="cerrar()" style="font-size: 28px"></i>
      </div>
    </div>
  </div>
</template>
<script>
 export default { 
  mounted(){
    console.log(this.$route.query.review, '-- - -query');
  },
  methods: {
    cerrar(){
        if(this.$route.query.review){
          this.$router.push({name: 'JuegosRevision'})
        }else {
          this.$router.push({name: 'Juegos'})
        }
       
    }
  }
 }
</script>
<style lang="scss" scoped>
.Nav {
  background: white;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  i {
    cursor: pointer;
  }
}
</style>
