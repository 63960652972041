const userDefaults = JSON.parse(localStorage.getItem('user')) || null;
const notificacionesDefault = JSON.parse(localStorage.getItem('notificacionesActive')) || false;

const state = {
    user: userDefaults,
    token: localStorage.getItem('access_token') || null,
    loader: false,
    loaderMensaje: "Cargando ...",
    efecto: false,
    efectoMensaje: "Cargando ...",
    notificaciones: [],
    alertNotificacion: false,
    bgFondo: 'rgb(17 17 17)',
    bgFondoDg: 'radial-gradient(40% 20% at 50% 0, rgba(104, 104, 104, 0.85), #111111)',
    btnColor: '#ff5722',
    btnCreate: '#4caf50',
    busqueda: null,
    menuGamer: 1,
    buscarGlobal: null,
    cerrarModalGlobal: true,
    sidebarActive: false,
    notificacionesActive: notificacionesDefault,
    mostrar: null,
    juegocreate:localStorage.getItem('juego_create') || 0,






}

export default state