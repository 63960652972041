<template>
  <div class="Sidebar">
    <div class="subSidebar">
      <div class="logod" style="text-align: left">
        <img src="/marca/logo.png" alt="" class="img-fluid" style="width: 130px" />
      </div>

      <div
        class="fichaAdmin d-flex flex-row"
        style="margin-top: 36px; align-items: center"
      >
        <div
          class="letra d-flex"
          style="
            background: rgb(239 239 239);
            height: 45px;
            width: 45px;
            text-align: center;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            color: #2d2d2d;
          "
        >
          <strong v-if="$store.state.user" style="text-transform:uppercase">{{ $store.state.user.name.substr(0,2) }}</strong>
        </div>
        <div
          class="contenido-letra d-flex flex-column"
          style="width: 70%; padding-left: 10px"
        >
          <div
            style="font-size: 13px; text-align: left; font-weight: 600"
            v-if="$store.state.user"
          >
            
            <p style="margin-bottom: 0;">{{ $store.state.user.name }}</p>
            <strong style="text-transform:uppercase">{{ $store.state.user.type }}</strong>
          </div>
        </div>
      </div>
      <!-- /////  -->
      <div class="menu" style="text-align: left">
        <ul class="navbar-nav mr-auto" style="margin-top: 25px; padding: 10px 0">
          <li
            class="item-menu d-flex flex-row"
            :style="$route.name == 'Dashboard' ? { color: '#28adca' } : {}"
            @click.prevent="cambiarRuta('Dashboard')"
          >
            <div style="width: 20%"><i class="fas fa-cube"></i></div>
            Dashboard
          </li>
          <li
            class="item-menu d-flex flex-row"
            :style="$route.name == 'Programas' ? { color: '#e91e63' } : {}"
            @click.prevent="cambiarRuta('Programas')"
          >
            <div style="width: 20%"><i class="fas fa-tv"></i></div>
            Programas
          </li>
          <li
            class="item-menu d-flex flex-row"
            :style="$route.name == 'Categorias' ? { color: '#28adca' } : {}"
            @click.prevent="cambiarRuta('Categorias')"
          >
            <div style="width: 20%"><i class="fa-light fa-list-timeline"></i></div>
            Categorías
          </li>

          <li
            class="item-menu d-flex flex-row"
            :style="$route.name == 'Juegos' ? { color: '#28adca' } : {}"
            @click.prevent="cambiarRuta('Juegos')"
          >
            <div style="width: 20%"><i class="fa-solid fa-gamepad"></i></div>
            Juegos
          </li>
          <li
            class="item-menu d-flex flex-row"
            :style="$route.name == 'JuegosRevision' ? { color: '#28adca' } : {}"
            @click.prevent="cambiarRuta('JuegosRevision')"
          >
            <div style="width: 20%"><i class="fas fa-bars-progress"></i></div>
            Juegos por revisar
          </li>

          <li
              class="item-menu d-flex flex-row"
              :style="$route.name == 'Equipos' ? { color: '#28adca' } : {}"
              @click.prevent="cambiarRuta('Equipos')"
            >
              <div style="width: 20%"><i class="fas fa-users"></i></div>
              Equipos
            </li>

          <div v-if="$store.state.user && $store.state.user.type == 'admin'">
            <hr />
            <li
              class="item-menu d-flex flex-row"
              :style="$route.name == 'Usuarios' ? { color: '#28adca' } : {}"
              @click.prevent="cambiarRuta('Usuarios')"
            >
              <div style="width: 20%"><i class="fas fa-user-cog"></i></div>
              Usuarios
            </li>
          </div>
        </ul>
      </div>

      <div
        class="bloque d-flex flex-column"
        style="
          position: absolute;
          bottom: 35px;
          text-align: center;
          justify-content: center;
          align-items: center;
          margin: auto;
          width: 69%;
        "
      >
        <strong>Powered By</strong>

        <img
          src="/marca/logounocomaseis.png"
          alt=""
          class="img-fluid"
          style="width: 58px;"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    cambiarRuta(ruta) {
      if (this.$route.name != ruta) {
        this.$router.push({ name: ruta });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.Sidebar {
  top: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding: 10px;
  background: white;
  position: fixed;
  left: 0;
  width: 15.5rem;

  height: 100%;
  padding: 2rem 1rem;
  pointer-events: auto;

  .subSidebar {
    max-width: 12.5rem;
  }

  .item-menu {
    margin-bottom: 30px;
    border-radius: 10px;
    padding: 3px 10px;
    background: rgb(255, 255, 255);
    font-weight: bold;
    cursor: pointer;
    color: #333;
  }
}
</style>
