import Vue from 'vue'
import VueRouter from 'vue-router'
import maquetaDashboard from '../Maqueta/Dashboard/index.vue'
import maquetaAuth from '../Maqueta/Auth/index.vue'
import createView from '../Maqueta/Create/index.vue'
import store from '../store/store'
Vue.use(VueRouter)

const routes = [

    //  Rutas para el auth  
    {
        path: '/',
        name: 'maquetaAuth',
        component: maquetaAuth,
        children: [{
            path: '',
            name: 'Login',
            component: () =>
                import ('../views/Auth/Login.vue')
        }]
    },


    {
        // RUTAS PARA EL ADMINISTRADOR
        path: '/dashboard',
        name: 'maquetaDashboard',
        component: maquetaDashboard,
        children: [{
                path: '',
                name: 'Dashboard',
                component: () =>
                    import ('../views/Dashboard.vue')
            },
            {
                path: 'programas',
                name: 'Programas',
                component: () =>
                    import ('../views/Programas/index.vue')
            },
            {
                path: 'categorias',
                name: 'Categorias',
                component: () =>
                    import ('../views/Categorias/index.vue')
            },

            {
                path: 'juegos',
                name: 'Juegos',
                component: () =>
                    import ('../views/Juegos/index.vue')
            },
            {
                path: 'juegos/revision',
                name: 'JuegosRevision',
                component: () =>
                    import ('../views/Juegos/indexRevision.vue')
            },

            {
                path: 'usuarios',
                name: 'Usuarios',
                component: () =>
                    import ('../views/Usuarios/index.vue')
            },

            {
                path: 'equipos',
                name: 'Equipos',
                component: () =>
                    import ('../views/Equipos/index.vue')
            }

        ],
        meta: {
            requiresAuth: true,
        }
    },

    // {
    //     path: '/print/programa/:id',
    //     name: 'ProgramaView',
    //     component: import ('../views/Programas/verProgramaPrint.vue'),
    //     props: true,
    //     meta: {
    //         requiresAuth: true,
    //     }

    // },

    {
        path: '/print/programa/:id',
        name: 'ProgramaView',
        component: () =>
            import ('../views/Programas/verProgramaPrint.vue'),
        props: true,
        children: [],
        meta: {
            requiresAuth: true,
        }
    },

    {
        path: '/print/ultima/:id',
        name: 'UltimaView',
        component: () =>
            import ('../views/Juegos/printUltima.vue'),
        props: true,
        children: [],
        meta: {
            requiresAuth: true,
        }
    },








    // Vistas para las creaciones full screen  
    {
        path: '/juego',
        name: 'Create',
        component: createView,
        props: true,
        children: [
            // created s
            {
                path: ':tipo_juego/create/palabrasencadenadas',
                name: 'createJuego',
                props: true,
                component: () =>
                    import ('../views/Juegos/palabrasencadenadas/create.vue')
            },
            {
                path: ':tipo_juego/create/minicadena',
                name: 'createJuegoMinicadena',
                props: true,
                component: () =>
                    import ('../views/Juegos/minicadena/create.vue')
            },
            {
                path: ':tipo_juego/create/centrocadena',
                name: 'createJuegoCentrocadena',
                props: true,
                component: () =>
                    import ('../views/Juegos/centrocadena/create.vue')
            },
            {
                path: ':tipo_juego/create/embudo',
                name: 'createJuegoEmbudo',
                props: true,
                component: () =>
                    import ('../views/Juegos/elembudo/create.vue')
            },

            {
                path: ':tipo_juego/create/unallevalaotra',
                name: 'createJuegoUnallevalaotra',
                props: true,
                component: () =>
                    import ('../views/Juegos/unallevalaotra/create.vue')
            },
            {
                path: ':tipo_juego/create/complicidadganadora',
                name: 'createJuegoComplicidadganadora',
                props: true,
                component: () =>
                    import ('../views/Juegos/complicidadganadora/create.vue')
            },
            {
                path: ':tipo_juego/create/ultima',
                name: 'createJuegoUltima',
                props: true,
                component: () =>
                    import ('../views/Juegos/ultima/create.vue')
            },




            // updates 
            {
                path: 'update/:uuid/juego',
                name: 'updateJuego',
                props: true,
                component: () =>
                    import ('../views/Juegos/palabrasencadenadas/update.vue')
            },
            {
                path: 'update/:uuid/juego/minicadena',
                name: 'updateJuegoMinicadena',
                props: true,
                component: () =>
                    import ('../views/Juegos/minicadena/update.vue')
            },
            {
                path: 'update/:uidd/juego/centrocadena',
                name: 'updateJuegoCentroCadena',
                props: true,
                component: () =>
                    import ('../views/Juegos/centrocadena/update.vue')
            },
            {
                path: 'update/:juego/juego/embudo',
                name: 'updateJuegoEmbudo',
                props: true,
                component: () =>
                    import ('../views/Juegos/elembudo/update.vue')
            },

            {
                path: 'update/:uuid/juego/unallevalaotra',
                name: 'updateJuegoUnallevalaotra',
                props: true,
                component: () =>
                    import ('../views/Juegos/unallevalaotra/update.vue')
            },
            {
                path: 'update/:uuid/juego/complicidadganadora',
                name: 'updateJuegoComplicidadganadora',
                props: true,
                component: () =>
                    import ('../views/Juegos/complicidadganadora/update.vue')
            },
            {
                path: 'update/:uuid/juego/ultima',
                name: 'updateJuegoUltima',
                props: true,
                component: () =>
                    import ('../views/Juegos/ultima/update.vue')
            },

        ],
        meta: {
            requiresAuth: true,
        }
    },




]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.loggedIn) {
            console.log('requiresAuth');
            next({ name: 'Login' })
        } else {
            next()
        }
    } else {
        console.log('NO requiresAuth');
        next() // make sure to always call next()!
    }
})





export default router