<template>
  <div class="Nav">
    <!-- <ModalDocumentos /> -->

    <div class="contenedor" style="height: 100%">
     
     

      <div
        class="notificacion d-flex"
        style="
          height: 100%;
          align-items: center;
          justify-content: right;
          padding-right: 100px;
          font-size: 22px;
        "
      >
        <div class="cuerpoIconBell">
          <i
            class="fas fa-circle"
            v-if="$store.state.notificacionesActive == true"
            style="color: #ff0000; font-size: 9px; position: absolute; margin-left: 4px"
          ></i>
          <div class="dropdown">
            <i
              class="fa-solid fa-bell"
              data-toggle="dropdown"
              aria-expanded="false"
              @click.prevent="actualizarNotificaciones()"
            ></i>
            <ul
              class="dropdown-menu" style="padding-top: 0;
    padding-bottom: 0;"
              aria-labelledby="dropdownMenuButton1"
              v-if="notificaciones"
            >
              <li v-for="(item, index) in notificaciones" :key="index">
                <a
                  class="dropdown-item"
                  @click.prevent="verObjeto(item.juego)"
                  href="#"
                  style="padding: 16px; white-space: inherit; border-bottom: 1px solid #eee"
                >
                  <div class="titulo">
                    <strong
                      style="
                        font-size: 13px;
                        border-radius: 5px;
                        color: #727272;
                      "
                      >{{ item.titulo }}</strong
                    >
                  </div>
                  <div class="contenido d-flex flex-column" style="font-size: 14px">
                    <div v-html="item.contenido" style="display: inline-block; font-size:14px"></div>
                    <div v-if="item.juego" style="display: inline-block;" class="mr-1">
                      <div class="tipo" v-if="item.juego.tipo_juego == '1'">
                        PALABRAS ENCADENADAS
                      </div>
                      <div class="tipo" v-if="item.juego.tipo_juego == '2'">
                        LA MINICADENA
                      </div>
                      <div class="tipo" v-if="item.juego.tipo_juego == '3'">
                        CENTRO DE LA CADENA
                      </div>
                      <div class="tipo" v-if="item.juego.tipo_juego == '4'">
                        EL EMBUDO
                      </div>
                      <div class="tipo" v-if="item.juego.tipo_juego == '5'">
                        UNA LLEVA LA OTRA
                      </div>
                      <div class="tipo" v-if="item.juego.tipo_juego == '6'">
                        COMPLICIDAD GANADORA
                      </div>
                      <div class="tipo" v-if="item.juego.tipo_juego == '7'">
                        LA ÚLTIMA CADENA (Y LA ÚLTIMA PALABRA)
                      </div>
                    </div>
                   
                  </div>
                  <div class="d-flex flex-row">
                   
                    <div v-if="item.juego" class="">
                      <div
                        v-if="item.state == 0"
                        class="d-flex flex-row"
                        style="align-items: center"
                      >
                        <i
                          class="fas fa-circle"
                          style="margin-right: 10px; font-size: 10px; color: gray"
                        ></i>
                      </div>
                      <div
                        v-if="item.state == 1"
                        class="d-flex flex-row"
                        style="align-items: center"
                      >
                        <i
                          class="fas fa-circle"
                          style="
                            margin-right: 10px;
                            font-size: 10px;
                            color: rgb(246, 201, 1);
                          "
                        ></i>
                      </div>
                      <div
                        v-if="item.state == 2"
                        class="d-flex flex-row"
                        style="align-items: center"
                      >
                        <i
                          class="fas fa-circle"
                          style="margin-right: 10px; font-size: 10px; color: #f44336"
                        ></i>
                      </div>
                      <div
                        v-if="item.state == 3"
                        class="d-flex flex-row"
                        style="align-items: center"
                      >
                        <i
                          class="fas fa-circle"
                          style="margin-right: 10px; font-size: 10px; color: #4caf50"
                        ></i>
                      </div>
                      <div
                        v-if="item.state == 4"
                        class="d-flex flex-row"
                        style="align-items: center"
                      >
                        <i
                          class="fas fa-circle"
                          style="
                            margin-right: 10px;
                            font-size: 10px;
                            color: rgb(15, 139, 215);
                          "
                        ></i>
                      </div>
                    </div>
                    <small style="font-size: 11px">{{
                      moment(item.created_at).format("DD/MM/YYYY") 
                    }} {{  moment(item.created_at).format("HH:mm") }}</small>

                  </div>
                </a>
              </li>
            </ul>
            <ul
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
              v-else
              style="padding: 20px"
            >
              <li>
                <strong>No existen notificaciones</strong>
              </li>
            </ul>
          </div>
        </div>
        <i
          class="fa-solid fa-arrow-right-from-bracket"
          style="margin-left: 80px; color: red"
          @click.prevent="logout()"
        ></i>
      </div>
    </div>
  </div>
</template>
<script>
import bootstrap from "bootstrap/dist/js/bootstrap.js";

export default {
  mounted() {
    this.listarNotificacion();
  },
  data() {
    return {
      notificaciones: null,
      mostrar: null,
    };
  },
  components: {
    // ModalDocumentos
  },
  methods: {
    verObjeto(item) {
      if (item.tipo_juego == 1) {
        this.$router.push("/juego/update/" + item.id + "/juego");
      } else if (item.tipo_juego == 2) {
        this.$router.push("/juego/update/" + item.id + "/juego/minicadena");
      } else if (item.tipo_juego == 3) {
        this.$router.push("/juego/update/" + item.id + "/juego/centrocadena");
      } else if (item.tipo_juego == 4) {
        this.$router.push("/juego/update/" + item.id + "/juego/embudo");
      } else if (item.tipo_juego == 5) {
        this.$router.push("/juego/update/" + item.id + "/juego/unallevalaotra");
      } else if (item.tipo_juego == 6) {
        this.$router.push("/juego/update/" + item.id + "/juego/complicidadganadora");
      } else if (item.tipo_juego == 7) {
        this.$router.push("/juego/update/" + item.id + "/juego/ultima");
      }
    },
    openModal() {
      var myModal = new bootstrap.Modal(
        document.getElementById("modalViewDocumentosGeneral"),
        {}
      );
      myModal.show();
    },

    listarNotificacion() {
      const url = this.$base.dominio + "/notificaciones";
      axios
        .post(url)
        .then((res) => {
          this.notificaciones = res.data[0] ? res.data : null;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    actualizarNotificaciones() {
      localStorage.setItem("notificacionesActive", JSON.stringify(false));
      this.$store.state.notificacionesActive = false;
    },
    logout() {
      this.$store
        .dispatch("destroyToken")
        .then((response) => {
          window.location.href = "/";
        })
        .catch((error) => {
          toastr.error("Error al cerrar la sesión");
        });
    },
  },
};
</script>
<style lang="scss" scoped>

.dropdown-item.active, .dropdown-item:active {
    color: #000;
    text-decoration: none;
    background-color: #f8f8f8;
}

.tipo {
  font-size: 11px;
  font-weight: 600;
  color: #333;
}
.Nav {
  z-index: 1;
  background: white;
  height: 60px;
  position: fixed;
  top: 0;
  left: 15.5rem;
  right: 0;
  i {
    cursor: pointer;
  }

  .dropdown-menu {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    width: 554px !important;
    transform: translate3d(-534px, 28px, 0px) !important;
  }
}
</style>
