<template>
  <div id="app" style="background:#f7f7f7; min-height:100vh">
    <Loader  v-if="$store.state.loader == true" />
    <router-view/>
  </div>
</template>
<script>
import Loader from './components/Maqueta/Loader.vue';
import SocketioService from "./services/socketio.service.js";

  export default {

    created() {
    SocketioService.setupSocketConnection();
  },
  beforeUnmount() {
    SocketioService.disconnect();
  },
  mounted(){
    if (this.$store.state.user) {
      console.log("se activo");
      SocketioService.socket.on("notificacion-global", (msg) => {
        console.log("si mando la funcion")
        localStorage.setItem("notificacionesActive", JSON.stringify(true));
        this.$store.state.notificacionesActive = true;
      });
    } else {
      console.log("user no registrado")
    }
  },


    components:{
      Loader,
    }
  }
</script>
<style>
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
</style>

<style lang="scss">

.input-verde {
    background: #0caf12 !important;
    color: #ffffff !important;

}

.input-azul {
  background: #586ddb !important;
    color: white !important;
}


.modal {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}
@media (min-width: 992px){
.modal-lg, .modal-xl {
    max-width: 900px !important;
}
}



body,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
small,
strong,
label {
  font-family: "Inter", sans-serif !important;
}



#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
